<template>
  <div class="control star-rating" :class="{clickable:$listeners.click}">
  	<template v-if="showGrey || $listeners.click">
    	<b-icon
        v-for="n in 5"
        icon="star"
        :type="n <= stars ? 'is-warning' : 'is-grey'"
        :key="n"
        @click.native="$emit('click', n)"
      />
    </template>
    <template v-else>
    	<b-icon
      	v-for="n in stars"
        icon="star"
        type="is-warning"
        :key="n"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  model:{
  	prop:'stars',
    event:'click'
  },
  props:{
  	stars:{
    	type:Number,
      default:0
    },
    showGrey:{
    	type:Boolean,
      default:false
    }
  },
  data () {
    return {
      isAuth: false,
      changeStateFav: false,
    }
  }
}
</script>

<style scoped lang="css">
  .star-rating.clickable .icon:hover{
    cursor:pointer;
    transform:scale(1.2);
  }
</style>
