<template>
  <div class="jsmb-account-commentaires">
    <div class="tabs">
      <ul>
        <li :class="toggleComment == 0  ? 'is-active' : ''" @click="toggleComment=0"><a>Commentaires sur vous</a></li>
        <li :class="toggleComment == 1  ? 'is-active' : ''" @click="toggleComment=1"><a>Commentaires envoyés</a></li>
      </ul>
    </div>
    <div v-if="toggleComment==0">
      <h2 class="jsmb-title-detail has-text-text is-size-4 is-uppercase">précédents commentaires</h2>
      <p>Les commentaires sont écrits à la fin d’un contrat sur JeStockeMonBateau. Les commentaires reçus sont visibles ici et sur votre profil public.</p>

      <div class="columns jsmb-commentaires-detail " :class="displayOnTouch ? ' ' : 'is-vcentered'" v-for="(theirComment, index) in comments.theirComments" :key="'comment-'+index">
        <div class="column is-2">
          <figure class="image " :class="displayOnTouch ? 'is-64x64' : 'is-96x96'">
            <img
              :src="theirComment.from.profil_picture!==null?theirComment.from.profil_picture:profilPicture"
              :alt="theirComment.from.name"
              class="is-rounded"
            >
          </figure>
        </div>
        <div class="column is-10 has-text-left">
          <h3 class="is-uppercase">{{ theirComment.from.name }}</h3>
          <StarRating :stars="theirComment.star" showGrey></StarRating>
          <p>{{ theirComment.message }}</p>
        </div>
      </div>
    </div>

    <div  v-if="toggleComment==1">
      <h2 class="jsmb-title-detail has-text-text is-size-4 is-uppercase">Commentaires envoyés</h2>
      <p>Vous trouverez ci-dessous l'ensemble des commentaires que vous avez envoyés à des stockeurs.</p>

      <div class="columns jsmb-commentaires-detail " :class="displayOnTouch ? ' ' : 'is-vcentered'" v-for="(myComment, index) in comments.myComments" :key="'comment-'+index">
        <div class="column is-2">
          <figure class="image " :class="displayOnTouch ? 'is-64x64' : 'is-96x96'">
            <img
              :src="myComment.to.profil_picture!==null?myComment.to.profil_picture:profilPicture"
              :alt="myComment.to.name"
              class="is-rounded"
            >
          </figure>
        </div>
        <div class="column is-10 has-text-left">
          <h3 class="is-uppercase">{{ myComment.to.name }}</h3>
          <StarRating :stars="myComment.star" showGrey></StarRating>
          <p>{{ myComment.message }}</p>
          <div class="buttons is-right">
            <a @click="removeComment(index)" class="button is-small is-danger">Supprimer ce commentaire</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from '@/components/template/ui/StarRating.vue'
import { GET_COMMENT_ACCOUNT, REMOVE_COMMENT } from '@/store/modules/database/actions/database.actions'

export default {
  name: 'Comments',
  components: {
    StarRating
  },
  data () {
    return {
      toggleComment:0,
      comments: [],
      profilPicture: require('./../../../assets/profil-modal.png')
    }
  },
  created() {
    // Get CommentAccount
    this.$store.dispatch(
      GET_COMMENT_ACCOUNT
    ).then(
      resp => {
        this.comments = resp
      }
    )
  },
  methods: {
    removeComment (commentIndex) {
      let commentId = this.comments.myComments[commentIndex].id
      // Get RemoveComment
      this.$store.dispatch(
        REMOVE_COMMENT,
        {commentId: commentId}
      ).then(
        resp => {
          const {success} = resp.data.response
          if (success) {
            this.comments.myComments.splice(commentIndex, 1)
          }
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.jsmb-account-information {
  padding-top:0 !important
}
.jsmb-account-commentaires .tabs li {
  border-top: 15px solid rgba(0,0,0,0);  
}
.jsmb-account-commentaires .tabs li.is-active {
  border-bottom-width: 0;
  border-top: 15px solid #14acc2;
}
.jsmb-account-commentaires .tabs ul {
  border-bottom: 0;
  justify-content: space-around;
}
.jsmb-account-commentaires .tabs a {
  text-transform: uppercase;
  padding-top: 2rem;
  font-weight: 100;
  border-bottom: 0;
}
.jsmb-commentaires-detail {
  margin-top: 2rem;
}
.jsmb-commentaires-detail h3 {
  color:#14acc2;
  font-weight: bold;
  text-transform: uppercase;
}
.jsmb-commentaires-detail p {
  font-size: 0.8rem
}
@media screen and (max-width: 617px){
  .jsmb-account-commentaires .tabs ul{
    flex-direction: column
  }
  .jsmb-account-commentaires .tabs li {
    border-top: 10px solid transparent;
    padding-bottom: 1rem;
  }
  .jsmb-account-commentaires .tabs li.is-active {
    border-top: 10px solid #14acc2
  }
  .jsmb-account-commentaires .tabs a {
    padding-top: 1rem;
  }
  
}
</style>
